<template>
  <div class="goods">
        <div class="classify">
            <div class="classify_box">
                <div :class="['classify_item',classifyActive==0?'classify_itemAtive':'']" @click="changeClassify(0)"
                :style="{'color':classifyActive==0?main_color:'#333333','--bgc':main_color}">全部商品</div>
                <div :class="['classify_item',classifyActive==item.id?'classify_itemAtive':'']" v-for="item in classify" :key="item.id" @click="changeClassify(item.id,item._child)" :data-color="main_color"
                :style="{'color':classifyActive==item.id?main_color:'#333333','--bgc':main_color}">{{item.name}}</div>
            </div>
        </div>
        <div class="classify s_classify" v-if="second_classify.length>0">
            <div class="classify_box">
                <div :class="['classify_item',second_classifyActive==item.id?'s_classify_itemAtive':'']"
                v-for="item in second_classify" :key="item.id" @click="changeSecendClassify(item.id)"
                 :style="{'color':second_classifyActive==item.id?main_color:'#333333'}">{{item.name}}</div>
            </div>
        </div>
        <div class="goodsbox" v-infinite-scroll="getList" :infinite-scroll-disabled="disabled" v-if="goodslist.length>0">
            <div class="goods_item"  v-for="item in goodslist" :key="item.id"
            :style="{'border-color':goodsActive==item.id?main_color:'transparent'}" @click.stop="chooseGoods(item)">
                <img class="goods_item_filter" v-if="isEmptyGoods(item)" :src="imageHttp+'empty.png'">
                <img :src="item.goods_picture" class="goods_item_image">
                <div class="goods_item_name">{{item.goods_name}}</div>
                <div class="goods_buy">
                    <div class="goods_item_price">￥{{item.goods_price}}</div>
                    <div :style="{'background-color':main_color}" class="goods_item_plus"
                     v-if="!isEmptyGoods(item)">
                        <img :src="imageHttp+'plus_fff.png'" class="goods_item_plus_in" >
                    </div>
                </div>
            </div>
        </div>
        <div v-if="empty" class="empty">暂无商品</div>
        <div class="spec" :style="{'height':isSpec?'300px':'0px'}" @click.stop >
            <div class="spec_box" v-if="isSpec">
                <div class="spec_row" v-for="(item,index) in specInfo.spec" :key="item.id">
                    <div class="spec_name">{{item.spec_name}}</div>
                    <div class="spec_valuebox">
                        <div :class="['spec_value',specActive[index]==v_item.id?'spec_valueActive':'',isEmptySpec(index,v_item.id)?'specEmpty':'']"
                        v-for="v_item in item.spec_value" :key="v_item.id" :style="{'--color':main_color}"
                        @click="chooseSpec(index,v_item.id)">
                        {{v_item.spec_value}}
                        </div>
                    </div>
                </div>
            </div>
             <div class="spec_operation" v-if="isSpec">
                <div class="spec_btn spec_cancle" @click="cancleSpec">取消</div>
                <div class="spec_btn spec_confirm" :style="{'background-color':main_color}" @click="confirmSpec">确认</div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props:['main_color','isSpec','order_goods'],
	data(){
		return{
            classify:[],
            classifyActive:0,
            goodslist:[],
            page:0,
            disabled:false,
            imageHttp:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Cashier/',
            empty:false,
            goodsActive:0,
            specInfo:null,
            specActive:[],
            second_classify:[],
            second_classifyActive:0,
            objGoods:null
		}
	},
    created(){
        this.getClassify()
        this.getList()
    },
    watch:{
        isSpec(val){
            if(!val){
                this.goodsActive = 0
            }
        }
    },
    methods:{
        getClassify(){
            this.$axios.post(this.$api.cashier.GetClassify)
            .then(res =>{
                if(res.code==0){
                    this.classify = res.result
                }
            })
        },
        getList(){
            this.page += 1
            this.$axios.post(this.$api.cashier.GetList,{
                page:this.page,
                rows:10,
                classify_id:this.second_classifyActive==0?this.classifyActive:this.second_classifyActive
            })
            .then(res =>{
                if(res.code==0){
                    let list = res.result.list
                    this.goodslist = [...this.goodslist,...list]
                    if(list.length<10){
                        this.disabled = true
                    }
                    if(this.goodslist.length==0){
                        this.empty = true
                    }
                }
            })
        },
        changeClassify(classifyActive,child=[]){
            this.page = 0
            this.empty = false
            this.classifyActive = classifyActive
            this.goodslist = []
            this.disabled = false
            this.spec = []
            this.second_classify = child
            if(child.length>0){
                this.second_classifyActive = child[0].id
            }else{
                this.second_classifyActive = 0
            }
            this.getList()
        },
        changeSecendClassify(id){
            this.page = 0
            this.empty = false
            this.second_classifyActive = id
            this.goodslist = []
            this.disabled = false
            this.spec = []
            this.getList()
        },
        chooseGoods(item){
            if(item.is_spec==1){
                if(item.id!=this.goodsActive){
                    this.$emit('close-popup','isSpec')
                    this.$axios.post(this.$api.cashier.GetInfo,{id:item.id})
                    .then(res =>{
                        if(res.code==0){
                             this.goodsActive = item.id
                            let info = res.result
							this.specInfo = info
							this.specActive = []
							info.spec.forEach(item=>{
								this.specActive.push(0)
							})
							this.$emit('open-popup','isSpec')
                        }
                    })
                }else{
                    this.$emit('close-popup','isSpec')
                }
            }else{
                 this.$emit('close-popup','isSpec')
                setTimeout(()=>{
                    this.goodsActive = item.id
                    let obj = item
                    obj.count = 1
                    obj.is_checked = true
                    obj.unitprice = obj.goods_price
                    this.$emit('add-cart', JSON.stringify(obj))
                    setTimeout(()=>{
                        this.goodsActive = 0
                    },200);
                },60);
            }
        },
        isEmptySpec(index,id){
            let a = false
            let arr = [...this.specActive]
            arr[index] = id
			let str = arr.toString()
			let count = 0
			let order_goods = []
			this.order_goods.forEach(item =>{
				if(item.is_checked){
					order_goods.push(item)
				}
			})
            let obj = {}
			order_goods.forEach(g_item=>{
				if(g_item.id===this.specInfo.id){
                    obj = g_item
				}
			})
            let flag = false
            if(obj.id){
                for(let s_item of this.specInfo.goods_spec){
                    if(obj.goods_spec.id == s_item.id){
                        count = obj.count
                        if(s_item.spec_itme_ids == str){
                            flag = true
                            a = count >= s_item.stock
                        }
                    }
                }
            }
            if(!flag){
                for(let s_item of this.specInfo.goods_spec){
                    if(s_item.spec_itme_ids == str){
                        a = count >= s_item.stock
                    }
                }
            }
            return a
        },
        isEmptyGoods(item){
			let a = false
			if(item.is_spec==0){
				let count = 0
				this.order_goods.forEach(g_item=>{
					if(g_item.id===item.id){
						count = g_item.count
					}
				})
				a = count>= item.goods_stock
			}
            return a
		},
        chooseSpec(index,id){
            if(!this.isEmptySpec(index,id)){
				let a = this.specActive[index]==id?0:id
				this.specActive.splice(index,1,a)

				let obj = {}
				this.goodslist.forEach(item => {
					if (item.id === this.goodsActive) {
                        obj = item
                    }
				})
				let flag = false
				this.specInfo.goods_spec.forEach(item => {
					if (item.spec_itme_ids === this.specActive.toString()) {
						flag = true
						obj.goods_spec = item
						obj.count = 1
						obj.unitprice = item.price
						obj.is_checked = false
						this.objGoods = obj

						this.$emit('preview-spec', JSON.stringify(obj))
					}
				})
				if (!flag) {
					this.$emit('handle-spec')
					this.objGoods = null
				}
			}
        },
        // 确认规格
        confirmSpec() {
			if (this.objGoods) {
				let obj = this.objGoods
				obj.is_checked = true
				this.$emit('add-cart', JSON.stringify(obj))
				this.objGoods = null
			}
		},
        // 取消规格
        cancleSpec(){
            this.$emit('close-popup','isSpec')
        }
    }
}
</script>

<style lang="less" scope>
    .goods{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .classify{
            margin: 20px 20px 0;
            border-bottom: 1px solid #EAEBEC;
            width: calc(100% - 40px);
            overflow-x: auto;
            overflow-y: hidden;
            .classify_box{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
            }
            .classify_item{
                font-size: 14px;
                font-family: Microsoft YaHei UI;
                font-weight: 400;
                color: #666666;
                margin-right: 82px;
                cursor: pointer;
                flex-shrink: 0;
                padding-bottom: 14px;
            }

            .classify_itemAtive{
                font-size: 15px;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    height: 2px;
                    border-radius: 1px;
                    left: 0;
                    right: 0;
                    background-color: var(--bgc) ;
                }
            }
        }
        .s_classify{
            border:none;
            margin: 16px 20px 0;
            .classify_item{
                padding-bottom: 0!important;
            }
            .s_classify_itemAtive{
                font-size: 15px;
                position: relative;
            }
        }
        .goodsbox{
            flex: 1;
            display: grid;
            width: calc(100% - 20px);
            margin: 16px 0px 20px 20px;
            grid-template-columns: repeat(auto-fill, 177px);
            grid-template-rows:  repeat(auto-fill, 250px);
            gap: 14px;
            overflow-y:auto;
            .goods_item{
                width: 177px;
                height: 250px;
                background: #F7F8FA;
                border-radius: 3px;
                position: relative;
                overflow: hidden;
                border: 1px solid transparent;
                cursor: pointer;
            }
            .goods_item_filter{
                position:absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 10;
            }
            .goods_item_image{
                width: 100%;
                height: 169px;
            }
            .goods_item_name{
                padding-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 14px;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }
            .goods_buy{
                padding: 0 10px;
                margin-top: 10px;
                color: #E90C00;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .goods_item_plus{
                width:24px;
                height:24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                .goods_item_plus_in{
                    width:16px;
                    height:16px;
                }
            }
        }
        .empty{
            font-size: 16px;
            margin-top: 50px;
            text-align: center;
            flex: 1;
        }
        .spec{
            width: 100%;
            transition: height 0.2s;
            position: relative;
            .spec_box{
                padding: 20px 0 20px 17px;
                overflow-y: auto;
                height: 100%;
                box-sizing: border-box;
                margin-right: 3px;
                background-color: #fff;
                box-shadow: 0px 11px 24px 0px rgba(183, 183, 183, 0.17);
                border-top: 1px solid #E9E9E9;
                border-radius: 3px 3px 0 0;
            }
            .spec_row{
                display: flex;
                margin-right: 100px;
                .spec_name{
                    width: 80px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #999999;
                    text-align: center;
                    line-height: 50px;
                }
                .spec_valuebox{
                    flex:1;
                    display: flex;
                    flex-wrap: wrap;
                    width: 0;
                }
                .spec_value{
                    min-width: 120px;
                    height: 50px;
                    padding: 0 30px;
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color:#666666;
                    position: relative;
                    background-color:#F2F6F9;
                    margin-right: 16px;
                    cursor: pointer;
                    margin-bottom: 16px;
                }
                .spec_valueActive{
                     border: 1px solid var(--color);
                     color:var(--color);
                    background-image: linear-gradient(rgba(255,255,255,0.89),rgba(255,255,255,0.89));
                    background-color: var(--color);
                }
                .specEmpty{
                    opacity: 0.4;
                    cursor: inherit;
                }
            }
            .spec_operation{
                position: absolute;
                right: 20px;
                top: 20px;
                .spec_btn{
                    width: 100px;
                    height: 116px;
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    cursor: pointer;
                }
                .spec_cancle{
                    background-color: #F2F6F9;
                    color: #666666;
                    margin-bottom: 16px;
                }
                .spec_confirm{
                    color: #ffffff;
                }
            }
        }
    }
</style>
